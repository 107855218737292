import axios from 'axios'
import router from "@/router";

const request = axios.create({
    baseURL:'api',
    // timeout:5000
})

const TOKEN_LIVE_TIME =  30 * 24 * 60 * 60 * 1000 
request.interceptors.request.use((config) => {
    let token = localStorage.getItem('token')
    if (token) {
    const LoginTime = parseInt(localStorage.getItem('LoginDate'))
    if (LoginTime + TOKEN_LIVE_TIME < Date.now()) {
        router.push('/login')
        localStorage.clear()
        return config
    }
    config.headers.Authorization = token
     }
  return config
},err => {
    return Promise.reject(err)
})

request.interceptors.response.use(response=>{
    let res = response.data
    if(res.code === '401'){
        router.push('/login')
    }else if(res.code === '404'){
        router.push('/404')
    }
    return res;
},err=>{
    return Promise.reject(err)
})

export default request
