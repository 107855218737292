import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/workSpace',
    component: ()=>import('@/views/home.vue'),
    children: [
      { path: 'workSpace', name: 'WorkSpace', meta:{name:'工作台'}, component: () => import('@/views/workSpace.vue') },
      { path: 'order', name: 'Order', meta:{name:'订单管理'}, component: () => import('@/views/Order/order.vue') },
      { path: 'serveOrder', name: 'ServeOrder', meta:{name:'服务订单管理'}, component: () => import('@/views/Order/serveOrder.vue') },
      { path: 'handOrder', name: 'HandOrder', meta:{name:'手续费订单管理'}, component: () => import('@/views/Order/handOrder.vue') },
      { path: 'message', name: 'Message', meta:{name:'消息管理'}, component: () => import('@/views/Order/message.vue') },
      { path: 'goods', name: 'Goods', meta:{name:'商品管理'}, component: () => import('@/views/Goods/goods.vue') },
      { path: 'category', name: 'Category', meta:{name:'商品分类管理'}, component: () => import('@/views/Goods/category.vue') },
      { path: 'report', name: 'Report', meta:{name:'数据统计管理'}, component: () => import('@/views/Report/report.vue') },
      { path: 'visit', name: 'Visit', meta:{name:'回访管理'}, component: () => import('@/views/Visit/visit.vue') },
      { path: 'user', name: 'User', meta:{name:'用户管理'}, component: () => import('@/views/User/user.vue') }
    ]
  },
  {
    path: '/login',
    meta:{name:'登录'},
    component: ()=>import('@/views/login.vue')
  },
  {
    path: '*',
    meta: {name: '无法访问'},
    component: () => import('@/views/404.vue')
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next)=>{
  if(to.path == '/login'){
    next()
  }else{
    let token = localStorage.getItem('token')
    if(token){
      next()
    }else{
      next('/login')
    }
  }
})
export default router
